// @flow strict

import React from 'react';
import styles from './NavBar.module.scss';
import Logo from '../Logo/Logo';
import NavItems from './NavItems/NavItems';

type Props = {
  pathname: string;
};

const NavBar = ({pathname}: Props) => (
  <nav className={styles.navBar}>
    <Logo/>
    <NavItems pathname={pathname}/>
  </nav>
);

export default NavBar;

// @flow strict

import React from 'react';
import { Link } from 'gatsby';
import styles from './Logo.module.scss';
import logo from "../../../assets/logo/logo.svg";
import { Icon } from '../../Icon/Icon';
import getIcon from '../../../utils/getIcon';

const Logo = () => (
  <div className={styles.logo}>
    <Link to={'/'}>
      <Icon icon={getIcon('logo')}/>
    </Link>
  </div>
);

export default Logo;
// @flow strict
import React from 'react';
import styles from './Icon.module.scss';

type Circle = {
  cx?: string,
  cy?: string,
  r?: string,
};

type Props = {
  icon: {
    name: string,
    paths: string[],
    circle?: Circle,
    viewBox?: string,
  },
  onClick?: void;
};

export const Icon = ({icon, onClick}: Props) => (
  <svg className={styles.icon} viewBox={icon.viewBox} onClick={onClick}>
    {icon.paths.map((path, i) =>
      <path key={icon.name + i} d={path}/>,
    )}
    {icon.circle &&
    <circle cx={icon.circle.cx} cy={icon.circle.cy} r={icon.circle.r}/>
    }
  </svg>
);

// @flow strict
import type { Node as ReactNode } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './Layout.module.scss';
import useSiteMetadata from '../../hooks/use-site-metadata';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Page from '../Page/Page';
import favicon from '../../assets/icons/favicon.svg';
import Breakpoints from '../Breakpoints/Breakpoints';

type Props = {
  children: ReactNode;
  pathname: string;
  incomingTitle?: string;
};

const buildTitle = (mainTitle, incomingTitle) => {
  return `${mainTitle} | ${incomingTitle || 'Not Found'}`;
};

const Layout = ({children, pathname, incomingTitle}: Props) => {
  const {mainTitle} = useSiteMetadata();
  const pageTitle = buildTitle(mainTitle, incomingTitle);

  return (
    <Breakpoints>
      <div className={styles.layout}>
        <Helmet defer={false}>
          <title>{pageTitle}</title>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <link rel="icon" href={favicon}/>
        </Helmet>
        <Header pathname={pathname}/>
        <Page>
          {children}
        </Page>
        <Footer/>
      </div>
    </Breakpoints>
  );
};

export default Layout;

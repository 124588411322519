// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames/bind';
import styles from './NavLink.module.scss';

type Props = {
  isActive: boolean;
  path: string;
  label: string;
};

const cx = classNames.bind(styles);

const NavLink = ({isActive, path, label}: Props) => {
  const linkClassName = cx({
    'navLink__link': true,
    'navLink__link--active': isActive,
  });

  return (
    <li className={styles.navLink}>
      <Link to={path} className={linkClassName}>
        {label.toUpperCase()}
      </Link>
    </li>
  );
};

export default NavLink;

// @flow strict
import React from 'react';
import styles from './Header.module.scss';
import NavBar from './NavBar/NavBar';
import normalizePathname from '../../utils/normalizer';

type Props = {
  pathname: string;
};

const Header = ({pathname}: Props) => {
  const normalizedPathname = normalizePathname(pathname);

  return (
    <header className={styles.header}>
      <NavBar pathname={normalizedPathname}/>
    </header>
  );
};

export default Header;
import React, { Component, ReactNode } from 'react';
import debounce from 'lodash/debounce';

export const BreakpointType = {
  Desktop: 'desktop',
  Mobile: 'mobile',
  Tablet: 'tablet',
};

interface BreakpointsProps {
  children: ReactNode;
}

interface BreakpointsState {
  breakpoint: BreakpointType;
}

export const BreakpointsContext = React.createContext(BreakpointType.Desktop);

const getBreakPoint = (windowWidth: number): BreakpointType => {
  if (windowWidth >= 1024) {
    return BreakpointType.Desktop;
  }
  if (windowWidth >= 768) {
    return BreakpointType.Tablet;
  }
  return BreakpointType.Mobile;
};

class Breakpoints extends Component<BreakpointsProps, BreakpointsState> {
  state = {
    breakpoint: BreakpointType.Desktop,
  };
  handleDebounceResize = debounce(this.setWidth.bind(this), 50);

  componentDidMount() {
    this.setWidth();
    window.addEventListener('resize', this.handleDebounceResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDebounceResize);
  }

  setWidth(): void {
    const {innerWidth} = window;
    this.setState({
      breakpoint: getBreakPoint(innerWidth),
    });
  }

  render() {
    const {breakpoint} = this.state;
    const {children} = this.props;
    return (
      <BreakpointsContext.Provider value={breakpoint}>
        {children}
      </BreakpointsContext.Provider>
    );
  }
}

export default Breakpoints;
import React from 'react';
import styles from './Page.module.scss';

type Props = {
  children: React.Node
};

const Page = ({children}: Props) => (
  <div className={styles.page}>
    {children}
  </div>
);

export default Page;

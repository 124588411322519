import React from 'react';
import styles from './NavItems.module.scss';
import NavLink from './NavLink/NavLink';
import useSiteMetadata from '../../../../hooks/use-site-metadata';
import { BreakpointsContext, BreakpointType } from '../../../Breakpoints/Breakpoints';
import NavDrawer from '../NavDrawer/NavDrawer';

type Props = {
  pathname: string;
};

const NavItems = ({pathname}: Props) => {
  const {menu} = useSiteMetadata();

  const renderLinks = () => {
    return (
      <ul className={styles.navItems}>
        {menu.map((item) =>
          <NavLink
            isActive={item.path === pathname}
            key={item.path}
            label={item.label}
            path={item.path}/>)}
      </ul>
    );
  };

  const renderNavMenu = () => {
    return (
      <NavDrawer menuItems={menu} pathname={pathname}/>
    );
  };

  const renderNavItems = (type: string) => {
    if (type === BreakpointType.Mobile) {
      return renderNavMenu();
    }
    return renderLinks();
  };

  return (
    <div>
      <BreakpointsContext.Consumer>
        {type => (
          renderNavItems(type)
        )}
      </BreakpointsContext.Consumer>
    </div>
  );
};

export default NavItems;
// @flow strict
import React from 'react';
import styles from './Footer.module.scss';
import useSiteMetadata from '../../hooks/use-site-metadata';
import {Icon} from '../Icon/Icon';
import {getIcon} from '../../utils';

const Footer = () => {
  const {author: {contacts}} = useSiteMetadata();

  function onMailClick(email) {
    window.location.href = `mailto:${email}`;
  }

  return (
    <footer className={styles.footer}>
      <ul className={styles['footer__icons']}>
        <li className={styles['footer__icon']}>
          <Icon icon={getIcon('email')} onClick={() => onMailClick(contacts.email)}/>
        </li>
        <li className={styles['footer__icon']}>
          <a href={contacts.linkedin}>
            <Icon icon={getIcon('linkedin')}/>
          </a>
        </li>
        <li className={styles['footer__icon']}>
          <a href={contacts.instagram}>
            <Icon icon={getIcon('instagram')}/>
          </a>
        </li>
        <li className={styles['footer__icon']}>
          <a href={contacts.facebook}>
            <Icon icon={getIcon('facebook')}/>
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
const normalizePathname = (pathname: string) => {
  if (pathname.length === 1) {
    return pathname;
  }

  if(pathname.slice(-1) === '/') {
    return pathname.substring(0, pathname.length - 1);
  }
  return pathname;
}

export default normalizePathname;
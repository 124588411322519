// @flow strict
import React, { useState } from 'react';
import styles from './NavDrawer.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '../../../Icon/Icon';
import { getIcon } from '../../../../utils';
import NavDrawerItem from './NavDrawerItem/NavDrawerItem';

type Props = {
  menuItems: any;
  pathname: string;
};

const cx = classNames.bind(styles);

const NavDrawer = ({menuItems, pathname}: Props) => {
  const [isOpen, setOpen] = useState(false);

  const iconName = isOpen ? 'exitMenu' : 'menu';

  const navDrawer = cx({
    navDrawer: true,
    'navDrawer--open': isOpen,
  });

  const toggle = () => {
    setOpen(!isOpen);

  };

  const renderIcon = () => {
    return (
      <div className={styles.navDrawerIcon}>
        <Icon icon={getIcon(iconName)} onClick={toggle}/>
      </div>
    );
  };

  const renderDrawer = () => {
    if (!isOpen) {
      return (<div/>);
    }

    return (
      <div>
        {renderIcon()}
        {menuItems.map(item =>
          <NavDrawerItem
            isActive={item.path === pathname}
            key={item.path}
            label={item.label}
            path={item.path}/>)}
      </div>
    );
  };

  return (
    <div>
      {!isOpen && renderIcon()}
      <div className={navDrawer}>
        {renderDrawer()}
      </div>
    </div>
  );
};

export default NavDrawer;
// @flow strict

import ICONS from '../constants/icons';

const getIcon = (name: string) => {
  switch (name) {
    case 'facebook':
      return ICONS.FACEBOOK;
    case 'instagram':
      return ICONS.INSTAGRAM;
    case 'email':
      return ICONS.EMAIL;
    case 'linkedin':
      return ICONS.LINKEDIN;
    case 'arrowRight':
      return ICONS.ARROW_RIGHT;
    case 'arrowLeft':
      return ICONS.ARROW_LEFT;
    case 'logo':
      return ICONS.LOGO;
    case 'menu':
      return ICONS.MENU;
    case 'exitMenu':
      return ICONS.EXIT_MENU;
    default:
      return {};
  }
};

export default getIcon;

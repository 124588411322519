// @flow strict
import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            mainTitle
            author {
              name
              contacts {
                email
                linkedin
                instagram
                facebook
              }
            }
            menu {
              label
              path
            }
            projects {
              id
              label
              path
            }
          }
        }
      }`,
  );

  return site.siteMetadata;
};

export default useSiteMetadata;

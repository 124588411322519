//@flow strict
import React from 'react';
import classNames from 'classnames/bind';
import styles from './NavDrawerItem.module.scss';
import { Link } from 'gatsby';

type Props = {
  path: string;
  label: string;
  isActive: boolean;
};

const cx = classNames.bind(styles);

const NavDrawerItem = ({isActive, path, label}: Props) => {
  const linkClassName = cx({
    'navDrawerItem__link': true,
    'navDrawerItem__link--active': isActive,
  });

  return (
    <Link to={path} className={styles.navDrawerItem}>
      <div className={linkClassName}>
        {label.toUpperCase()}
      </div>
    </Link>
  );
};

export default NavDrawerItem;